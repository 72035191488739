import * as dictionaryTypes from '@/schemas/dictionaryTypes';
import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';

// eslint-disable-next-line import/prefer-default-export
export const schema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.DATE,
        label: 'tasks.date_create',
        prop: 'createdAt',
        rules: [rules.REQUIRED],
        size: 3,
      },
      {
        type: schemaItemTypes.STRING,
        label: 'task.title',
        prop: 'name',
        rules: [rules.REQUIRED],
        size: 3,
      },
      {
        type: schemaItemTypes.ENUM,
        label: 'invoice.service_type',
        prop: 'serviceType',
        dictionary: dictionaryTypes.SERVICE_TYPES,
        rules: [rules.REQUIRED],
        size: 3,
      },
    ],
  },
  {
    type: schemaItemTypes.STRING,
    label: 'task.description',
    prop: 'description',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'tasks.client',
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'task.client',
            prop: 'client',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'task.client_type',
            prop: 'clientType',
            rules: [rules.REQUIRED],
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'tasks.project',
            prop: 'project',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'tasks.building',
            prop: 'building',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'tasks.unit',
            prop: 'unit',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'tasks.room',
            prop: 'room',
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.STRING,
        label: 'tasks.inform_notification',
        prop: 'isPushInform',
      },
    ],
  },
  {
    label: 'label.roles',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'requests.pending',
            prop: 'pendingRoles',
            type: schemaItemTypes.MANY_ROLES,
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'requests.in_progress',
            prop: 'inProgressRoles',
            type: schemaItemTypes.MANY_ROLES,
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'requests.ready',
            prop: 'completedRoles',
            type: schemaItemTypes.MANY_ROLES,
            rules: [rules.REQUIRED],
            size: 3,
          },
        ],
      },
    ],
  },
  // {
  //   type: schemaItemTypes.TITLED_SECTION,
  //   label: 'label.payment',
  //   children: [
  //     {
  //       type: schemaItemTypes.TASK_PAYMENTS,
  //       prop: 'payments',
  //     },
  //   ],
  // },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'tasks.attachments',
    children: [
      {
        type: schemaItemTypes.FILE_LIST,
        prop: 'media',
        rules: [rules.REQUIRED, rules.ALL_LOADED],
        label: 'tasks.attachments',
      },
    ],
  },
  // {
  //   type: schemaItemTypes.TITLED_SECTION,
  //   label: 'tasks.history',
  //   children: [
  //     {
  //       type: schemaItemTypes.ROW,
  //       children: [
  //         {
  //           type: schemaItemTypes.HISTORY,
  //           prop: 'statusesHistory',
  //           size: 6,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'tasks.history',
    children: [
      {
        type: schemaItemTypes.LIST,
        prop: 'statusesHistory',
        uniqueKey: 'createdAt',
        children: [
          {
            type: schemaItemTypes.ROW,
            showLine: true,
            children: [
              {
                type: schemaItemTypes.DATE,
                prop: 'createdAt',
                rules: [rules.REQUIRED],
                size: 2,
              },
              {
                type: schemaItemTypes.STRING,
                prop: 'author',
                rules: [rules.REQUIRED],
                size: 2,
              },
              {
                type: schemaItemTypes.TASK_STATUS_CHANGE,
                prop: 'changeStatus',
                rules: [rules.REQUIRED],
                size: 4,
              },
              {
                type: schemaItemTypes.FILE_LIST,
                prop: 'files',
                rules: [rules.REQUIRED],
                size: 3,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const schemaWithTemplate = [
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'tasks.request',
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.DATE,
            label: 'tasks.date_create',
            prop: 'createdAt',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'task.title',
            prop: 'name',
            rules: [rules.REQUIRED],
            size: 3,
          },
          // {
          //   type: schemaItemTypes.STRING,
          //   label: 'label.type',
          //   prop: 'requestType',
          //   rules: [rules.REQUIRED],
          //   size: 3,
          // },
          // {
          //   type: schemaItemTypes.STRING,
          //   label: 'label.category',
          //   prop: 'requestCategory',
          //   rules: [rules.REQUIRED],
          //   size: 3,
          // },
        ],
      },
      {
        type: schemaItemTypes.STRING,
        label: 'task.description',
        prop: 'description',
        rules: [rules.REQUIRED],
      },
    ],
  },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'tasks.client',
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'label.name',
            prop: 'client',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'task.client_type',
            prop: 'clientType',
            rules: [rules.REQUIRED],
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'tasks.project',
            prop: 'project',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'tasks.building',
            prop: 'building',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'tasks.unit',
            prop: 'unit',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'tasks.room',
            prop: 'room',
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.STRING,
        label: 'tasks.inform_notification',
        prop: 'isPushInform',
      },
    ],
  },
  {
    label: 'label.roles',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'requests.pending',
            prop: 'pendingRoles',
            type: schemaItemTypes.MANY_ROLES,
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'requests.in_progress',
            prop: 'inProgressRoles',
            type: schemaItemTypes.MANY_ROLES,
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'requests.ready',
            prop: 'completedRoles',
            type: schemaItemTypes.MANY_ROLES,
            rules: [rules.REQUIRED],
            size: 3,
          },
        ],
      },
    ],
  },
  // {
  //   type: schemaItemTypes.TITLED_SECTION,
  //   label: 'label.payment',
  //   children: [
  //     {
  //       type: schemaItemTypes.TASK_PAYMENTS,
  //       prop: 'payments',
  //     },
  //   ],
  // },
  // {
  //   type: schemaItemTypes.TITLED_SECTION,
  //   label: 'tasks.history',
  //   children: [
  //     {
  //       type: schemaItemTypes.ROW,
  //       children: [
  //         {
  //           type: schemaItemTypes.HISTORY,
  //           prop: 'statusesHistory',
  //           size: 6,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'tasks.history',
    children: [
      {
        type: schemaItemTypes.LIST,
        prop: 'statusesHistory',
        uniqueKey: 'createdAt',
        children: [
          {
            type: schemaItemTypes.ROW,
            showLine: true,
            children: [
              {
                type: schemaItemTypes.DATE,
                prop: 'createdAt',
                rules: [rules.REQUIRED],
                size: 2,
              },
              {
                type: schemaItemTypes.STRING,
                prop: 'author',
                rules: [rules.REQUIRED],
                size: 2,
              },
              {
                type: schemaItemTypes.TASK_STATUS_CHANGE,
                prop: 'changeStatus',
                rules: [rules.REQUIRED],
                size: 4,
              },
              {
                type: schemaItemTypes.FILE_LIST,
                prop: 'files',
                rules: [rules.REQUIRED],
                size: 3,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const schemaRecurrence = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.DATE,
        label: 'tasks.date_create',
        prop: 'createdAt',
        rules: [rules.REQUIRED],
        size: 3,
      },
      {
        type: schemaItemTypes.DATE,
        label: 'task.date_first_task',
        prop: 'issueDateFirst',
        rules: [rules.REQUIRED],
        size: 3,
      },
      {
        type: schemaItemTypes.STRING,
        label: 'task.recurrence_period',
        prop: 'recurrencePeriod',
        rules: [rules.REQUIRED],
        size: 3,
      },
      {
        type: schemaItemTypes.DATE,
        label: 'task.date_last_task',
        prop: 'issueDateLast',
        rules: [rules.REQUIRED],
        size: 3,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'task.title',
        prop: 'name',
        rules: [rules.REQUIRED],
        size: 3,
      },
      {
        type: schemaItemTypes.ENUM,
        label: 'invoice.service_type',
        prop: 'serviceType',
        dictionary: dictionaryTypes.SERVICE_TYPES,
        rules: [rules.REQUIRED],
        size: 3,
      },
    ],
  },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'tasks.client',
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'task.client',
            prop: 'client',
            rules: [rules.REQUIRED],
            size: 3,
          },
          // {
          //   type: schemaItemTypes.STRING,
          //   label: 'task.client_type',
          //   prop: 'clientType',
          //   rules: [rules.REQUIRED],
          //   size: 3,
          // },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'tasks.project',
            prop: 'project',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'tasks.building',
            prop: 'building',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'tasks.unit',
            prop: 'unit',
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            type: schemaItemTypes.STRING,
            label: 'tasks.room',
            prop: 'room',
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.STRING,
        label: 'tasks.inform_notification',
        prop: 'isPushInform',
      },
    ],
  },
  {
    label: 'label.roles',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'requests.pending',
            prop: 'pendingRoles',
            type: schemaItemTypes.MANY_ITEMS,
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'requests.in_progress',
            prop: 'inProgressRoles',
            type: schemaItemTypes.MANY_ITEMS,
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'requests.ready',
            prop: 'completedRoles',
            type: schemaItemTypes.MANY_ITEMS,
            rules: [rules.REQUIRED],
            size: 3,
          },
        ],
      },
    ],
  },
  // {
  //   type: schemaItemTypes.TITLED_SECTION,
  //   label: 'label.payment',
  //   children: [
  //     {
  //       type: schemaItemTypes.TASK_PAYMENTS,
  //       prop: 'payments',
  //     },
  //   ],
  // },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'tasks.attachments',
    children: [
      {
        type: schemaItemTypes.FILE_LIST,
        prop: 'media',
        rules: [rules.REQUIRED, rules.ALL_LOADED],
        label: 'tasks.attachments',
      },
    ],
  },
];
